<template>
  <div
    class="banner"
    :class="{ w: imageFillType === 1 }"
    v-if="componentInfo.data.length > 0"
    :style="{ 'margin-top': imageFillType === 1 ? '20px' : '0px' }"
    @mouseout="mout"
    @mouseover="mover"
  >
    <div class="banner-content">
      <div class="w relative">
        <div
          :class="['classify_tree_wrapper', imageFillType === 1 ? '' : 'type2']"
          v-if="showNavMenu"
        >
          <div class="classify_tree_main">
            <classify-tree
              :class-list="navList"
              :style="'height:' + (imageFillType === 1 ? '416px' : '404px')"
            />
          </div>
        </div>
      </div>
      <ul
        class="banner-list"
        :style="'height:' + (imageFillType === 1 ? '416px' : '456px')"
      >
        <li
          v-for="(item, index) in componentInfo.data"
          :key="index"
          class="item"
          :class="{ on: index === num }"
        >
          <custom-link
            :to="handleNavigate(item)"
            :targetType="item.targetType"
            :targetOpenNewPage="item.targetOpenNewPage"
            custom-class="image_wrapper"
          >
            <div v-if="item.materialId">
              <div
                :id="`player${item.materialId}`"
                ref="videoBox"
                class="player"
                :style="[
                  { 'border-radius': imageFillType === 1 ? '10px' : '0px' },
                  { height: imageFillType === 1 ? '416px' : '456px' },
                ]"
              />
            </div>
            <img
              v-else
              class="image"
              :style="[
                { 'border-radius': imageFillType === 1 ? '10px' : '0px' },
                { height: imageFillType === 1 ? '416px' : '456px' },
              ]"
              :src="item.imageUrl"
              :alt="item.advTitle"
            />
          </custom-link>
        </li>
      </ul>
      <div class="w relative">
        <ul class="page_dots">
          <span
            v-for="(item, index) in componentInfo.data"
            :key="index"
            class="dots"
            :class="{ on: index === num }"
            @click="num = index"
            @mouseenter="num = index"
          />
        </ul>
      </div>
    </div>
    <div class="exam_box" v-if="domain === 'edu.shanxiyixue.com'">
      <div class="exam_countdown shake">
        <img src="@/assets/pc/countdown.png" class="countdown_image" alt="" />
        <div class="exam_time">{{ countdown }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref, toRefs, watch } from "vue";
import ClassifyTree from "./ClassifyTree.vue";
import initPlayer from "../../../player";
import { bannerVideoPreview } from "@/api/layouts";
export default defineComponent({
  components: {
    ClassifyTree,
  },
  props: {
    componentInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(props, { emit }) {
    const player = ref(null);
    const videoBox = ref(null);
    let timer;
    onMounted(() => {
      change();
      getTimeString();
      timer = setInterval(getTimeString, 1000);
      if (process.client) {
        state.domain = window.location.host;
      }
    });
    const state = reactive({
      currentTime: new Date(), // 当前时间
      endTime: new Date("2025-05-18 00:00:00"), // 设置考试结束时间,
      countdown: "",
      domain: "",
    });
    const getTimeString = () => {
      const diff = state.endTime - new Date(); // 计算剩余时间的毫秒数差
      const seconds = Math.floor(diff / 1000) % 60; // 计算剩余秒数
      const minutes = Math.floor(diff / 1000 / 60) % 60; // 计算剩余分钟数
      const hours = Math.floor(diff / 1000 / 60 / 60) % 24; // 计算剩余小时数
      const days = Math.floor(diff / 1000 / 60 / 60 / 24); // 计算剩余天数
      // 格式化剩余时间
      state.countdown = `${days} 天 ${hours} 小时 ${minutes} 分钟 ${seconds} 秒`;
      return state.countdown;
    };
    const imageFillType = computed(() => {
      return props.componentInfo.config.imageFillType;
    });
    const navList = computed(() => {
      return props.componentInfo.navList;
    });
    const showNavMenu = computed(() => {
      return props.componentInfo.config.showNavMenu;
    });
    const num = ref(0);
    const interval = ref(null);
    const change = () => {
      interval.value = setInterval(() => {
        if (num.value + 1 >= props.componentInfo.data.length) {
          num.value = 0;
        } else {
          num.value++;
        }
      }, 5000);
    };
    watch(num, (newValue, oldValue) => {
      getView();
    });
    const mout = () => {
      change();
    };
    const mover = () => {
      clearInterval(interval.value);
    };
    const getView = () => {
      if (props.componentInfo) {
        props.componentInfo.data.forEach((item, index) => {
          if (index === num.value) {
            if (item.materialId) {
              bannerVideoPreview({ id: item.materialId }).then((res) => {
                play(res);
              });
            }
          }
        });
      }
    };
    const play = (data, startTime = 0) => {
      if (!window.polyvPlayer) {
        play(data, startTime);
        return;
      }
      const box = videoBox.value;
      if (!box) {
        play(data, startTime);
        return;
      }
      document.querySelector("#player" + data.materialId).innerHTML = "";
      // 保利威 欢拓 私有化
      let param = null;
      param = {
        wrap: "#player" + data.materialId,
        width: box[0].offsetWidth,
        height: box[0].offsetHeight,
        history_video_duration: 2,
        cover_display: "scaleAspectFit",
        watchStartTime: startTime,
        forceH5: true, // 使用多终端代码时，是否默认使用H5播放器。注：浏览器不支持H5的情况下还是会使用Flash
        autoplay: true, // 是否自动播放
        loop: true, // 是否开启循环播放
        hideSwitchPlayer: false, // 是否隐藏H5和Flash播放器的切换按钮
        skinLocation: 0, // 播放器控制栏显示位置：0 不显示，1 视频区域内，2 视频区域外
        allowFullscreen: false, // 是否允许全屏播放
        ban_skin_progress: true, // 是否隐藏播放器进度条
        webPageFullScreen: false, // 是否开启网页全屏
      };
      const videoConfig = JSON.parse(data.videoConfig);
      param.ts = videoConfig.ts;
      param.sign = videoConfig.sign;
      param.vid = videoConfig.vid;
      param.playsafe = videoConfig.token;
      param.code = videoConfig.code;
      player.value = initPlayer(param, data.vodPlatform);
    };
    return {
      player,
      videoBox,
      ...toRefs(state),
      num,
      interval,
      mout,
      mover,
      imageFillType,
      navList,
      showNavMenu,
      getTimeString,
      getView,
      play,
    };
  },
});
</script>

<style lang="scss">
.banner {
  position: relative;
  .banner-content {
    .image_wrapper {
      width: 100%;
      height: 100%;
    }
  }
  .exam_box {
    position: absolute;
    top: -20px;
    left: -285px;
    z-index: 100;
    .exam_countdown {
      position: fixed;
      width: 330px;
      height: 140px;

      .countdown_image {
        height: 200px;
      }
      .exam_time {
        position: absolute;
        top: 165px;
        left: 75px;
        color: #4db884;
      }
    }
    .shake:hover {
      animation: shake 800ms ease-in-out;
    }
    @keyframes shake {
      /* 水平抖动，核心代码 */
      10%,
      90% {
        transform: translate3d(-1px, 0, 0);
      }
      20%,
      80% {
        transform: translate3d(+2px, 0, 0);
      }
      30%,
      70% {
        transform: translate3d(-4px, 0, 0);
      }
      40%,
      60% {
        transform: translate3d(+4px, 0, 0);
      }
      50% {
        transform: translate3d(-4px, 0, 0);
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.banner {
  position: relative;

  .banner-content {
    margin: 0 auto;
    position: relative;
    // background: #e3e3e3;
    overflow: hidden;
  }

  ul {
    position: relative;
  }

  .item {
    opacity: 0;
    height: 100%;
    background-position: center center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    transition: all 1s;

    &.on {
      opacity: 1;
      z-index: 10;
    }

    a {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .page_dots {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 15px;
    right: 15px;
    padding: 0px 15px 0;
    text-align: center;
    border-radius: 13px;
    height: 26px;
    // background: rgba(0, 0, 0, 0.2);
    z-index: 11;

    &.old {
      left: 0;
      right: 0;
      margin-right: 0;
      bottom: 30px;
    }

    .dots {
      display: inline-block;
      width: 8px;
      height: 6px;
      margin: 0 4px;
      background: #becadb;
      border-radius: 15px;

      &.on {
        width: 14px;
        background: #2256f6;
      }
    }
  }

  .classify_tree_wrapper {
    z-index: 300;
    position: absolute;
    left: 0px;
    top: 0px;

    &:deep(.classify-tree > .wrapper) {
      padding-top: 18px;
    }

    &.type2 {
      top: 26px;
      bottom: 26px;

      &:deep(.classify-tree > .wrapper) {
        padding-top: 13px;
      }
    }

    .classify_tree_main {
      margin: auto;

      .classify_tree {
        width: 100%;
      }
    }
  }
}

.image_wrapper {
  width: 100%;
  height: 100%;

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.player {
  width: calc(100%);
  height: calc(100%);
  position: relative;
}
:deep(video.pv-video) {
  height: 416px;
}
</style>
