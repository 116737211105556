<template>
  <div>
    <div class="material_content">
      <div class="icon_preview_content" @click="handleClick">
        <div class="icon">
          <img src="@/assets/images/material/material_img.png" alt="" />
        </div>
      </div>
    </div>
    <client-only>
      <el-dialog
        append-to-body
        class="custom-dialog"
        :model-value="ctrl.dialogModel"
        :before-close="handleClose"
        :show-close="false"
        width="438px"
      >
        <div class="dialog_close" @click="handleClose">
          <img src="@/assets/images/material/material_close.png" />
        </div>
        <div class="dialog_box">
          <el-form :model="opts" size="large" label-position="top">
            <el-form-item prop="inviteCode" label="邀请码(非必填)">
              <el-input v-model="opts.inviteCode" class="submit_input" />
            </el-form-item>
          </el-form>
          <div class="receive_btn" @click="handleReceive">
            <div class="btn">领取资料</div>
          </div>
        </div>
      </el-dialog>
    </client-only>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, reactive, watch } from "vue";
import { ElMessage } from "element-plus";
import { packetTake } from "@/api/material";
const router = useRouter();
const opts = reactive({
  inviteCode: "",
});
const ctrl = reactive({
  dialogModel: false,
});
const handleClick = () => {
  ctrl.dialogModel = true;
};
const handleClose = () => {
  opts.inviteCode = "";
  ctrl.dialogModel = false;
};
const handleReceive = () => {
  packetTake(opts).then((res) => {
    if (res) {
      ElMessage.success("领取成功");
      router.push("/account/material");
    }
  });
};
onMounted(() => {});
</script>
<style lang="scss">
.custom-dialog {
  width: 438px;
  height: 400px;
  background: transparent;
  background-image: url("@/assets/images/material/material_bg.png");
  background-size: 100% 100%;
  box-shadow: none;
  background-repeat: no-repeat;

  .el-dialog__header {
    padding: 0;
  }
}
</style>
<style lang="scss" scoped>
.material_content {
  padding: 5px 0;
  overflow: hidden;
  z-index: 100;
  position: fixed;
  bottom: 3%;
  right: 4%;
  transform: translateY(-50%);
  width: 100px;
  border-radius: 5px;
}
.icon_preview_content {
  cursor: pointer;
  .icon {
    width: 100px;
    height: 100px;
    transition: all 0.3s;
    img {
      width: 100px;
      height: 100px;
      transition: all 0.3s;
    }
  }
}
.submit_input {
  :deep(.el-input__wrapper) {
    background-color: #ededed;
  }
}
.dialog_close {
  position: fixed;
  top: 14px;
  right: 22px;
  width: 28px;
  height: 28px;
  cursor: pointer;
  z-index: 999;
}
.dialog_box {
  position: relative;
  top: 43%;
  left: 15%;
  width: 275px;
}
.receive_btn {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  width: 120px;
  height: 32px;
  line-height: 32px;
  text-align: center;

  .btn {
    font-size: 14px;
    width: 100%;
    color: #fff;
    background: #fd431b;
    border-radius: 16px;
    text-align: center;
  }
}
</style>
